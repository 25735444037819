import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import LayoutDefault from '../layout/LayoutDefault.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Volei',
    component: LayoutDefault,
    redirect: '/',
    children: [
      {
        path: '/',
        name: 'Home',
        meta: {
          title: 'Lista de jogadores'
        },
        component: () => import(/* webpackChunkName: "home" */'../views/HomeView.vue')
      },
      {
        path: '/partida',
        name: 'partida',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/PartidaView.vue'),
        meta: {
          title: 'PARTIDA'
        }
      }
    ]

  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
